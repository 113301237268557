import { createClient } from '@supabase/supabase-js';
import AppConst from './constants/app.constant';
import SupabaseConst from './constants/supabase.constant';
import { getEnv, ENVIRONMENT } from './services/getEnv';

export const getDerivedSupabaseConfig = () => {
  const env = getEnv();
  if (env === ENVIRONMENT.TEST || env === ENVIRONMENT.LOCAL) {
    return SupabaseConst.dev;
  }
  return SupabaseConst.prod;
};

const getSupabaseConfigForConnection = () => {
  const supaApiKey = localStorage.getItem(
    AppConst.localstorageConf.prefix + AppConst.localstorageConf.supaApiKey,
  );
  const supaApiUrl = localStorage.getItem(
    AppConst.localstorageConf.prefix + AppConst.localstorageConf.supaApiUrl,
  );
  const derivedSupaConfig = getDerivedSupabaseConfig();
  // prefer localstorage value otherwise derived config
  return {
    endpoint: supaApiUrl || derivedSupaConfig.endpoint,
    key: supaApiKey || derivedSupaConfig.key,
  };
};

const supabaseConfig = getSupabaseConfigForConnection();

const initSupaClient = () => {
  try {
    return createClient(supabaseConfig.endpoint, supabaseConfig.key, {
      auth: {
        storageKey: 'supabase_auth',
      },
    });
  } catch (ex) {
    console.log(ex);
  }
};

export const supabase = initSupaClient();
