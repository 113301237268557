import secureLsService from '../secureLs.service';
import AppConst from '../../constants/app.constant';
import { supbaseSignUpNewUser } from '../supabase/supbaseSignUpNewUser';
import { supbaseSignInWithEmail } from '../supabase/supbaseSignInWithEmail';
import reportError from '../error-handler/reportError';
import { getTuValue } from '../aws/utils/getTuValue';
import { setUserFavroites } from '../aws/user/setAttributes/setUserFavroites';
import { setAirportSearchHistory } from '../aws/user/setAttributes/setAirportSearchHistory';

function removeCognitoLocalStorage() {
  for (let i = 0; i < localStorage.length; i += 1) {
    const key = localStorage.key(i);
    if (key.toLowerCase().includes('cognito')) {
      localStorage.removeItem(key);
    }
  }
}

const setMigrationDone = () => {
  localStorage.removeItem('bn_uc_copy');
  localStorage.removeItem('bn_uc');
};

const signinAfterMigration = async ({ Password, Username }) => {
  const signinResp = await supbaseSignInWithEmail({
    username: Username,
    userpassword: Password,
  });
  if (signinResp.error) {
    console.error('Error signin after migration completion', signinResp.error);
    reportError({
      message: 'Supabase migration Signin failed',
      code: 'SupabaseMigration',
      name: signinResp.error.message,
      Username,
      signinResp,
    });
  }
  return signinResp;
};

const saveMigrationData = async () => {
  const favourites =
    JSON.parse(
      localStorage.getItem(
        AppConst.localstorageConf.prefix + AppConst.localstorageConf.favoriteList,
      ),
    ) || [];
  const airportSearchHistory =
    JSON.parse(
      localStorage.getItem(AppConst.localstorageConf.prefix + AppConst.localstorageConf.airportKey),
    ) || [];
  try {
    await Promise.allSettled([
      setAirportSearchHistory(airportSearchHistory),
      setUserFavroites(favourites),
    ]);
  } catch (error) {
    console.error('Error saving migration data:', error);
    reportError(error);
  }
};

const alreadyAwsLoggedInStrategy = async ({ Password, Username }) => {
  if (Username && Password) {
    const application_settings =
      JSON.parse(
        localStorage.getItem(
          AppConst.localstorageConf.prefix + AppConst.localstorageConf.applicationSettingKey,
        ),
      ) || {};
    const signupPayload = {
      data: {
        airport_search_history: [],
        favourites: [],
        application_settings,
        app_install_date: getTuValue(),
        email: Username,
        first_name: '',
        last_name: '',
      },
    };
    const { data, error } = await supbaseSignUpNewUser(Username, Password, signupPayload);
    if (error) {
      if (error?.message === 'User already registered') {
        // if user was already registerd then we can ignore that
        setMigrationDone();
        console.log('User already registered in supabase so will try to auto login with passoword in ls');
        await signinAfterMigration({ Username, Password });
        console.error(error);
        return;
      }
      reportError({
        message: 'Supabase migration failed',
        code: 'SupabaseMigration',
        name: error.message,
        error,
        Username,
        signupPayload,
      });
      console.error(error);
      return;
    }
    setMigrationDone();
    if (data.session) {
      const signinResult = await signinAfterMigration({ Username, Password });
      if (!signinResult.error) {
        await saveMigrationData();
      }
    }
  }
};

const supabaseMigration = async () => {
  removeCognitoLocalStorage();
  // if migration done then dont do anything
  // already loggedin no need for migration
  if (localStorage.getItem('supabase_auth')) {
    return;
  }
  const user = secureLsService.getInfo(AppConst.localstorageConf.uc_copy);
  if (user) {
    await alreadyAwsLoggedInStrategy(user);
  }
};

export { supabaseMigration };
