import utilityService from '../utility.service';

const getStripeRedirectUrls = () => {
  if (utilityService.isCordovaApplication()) {
    return {
      successURL: 'https://app.weatherspork.com/mob.html',
      cancelURL: 'https://app.weatherspork.com/mob.html',
    };
  }
  const base = `${window.location.protocol}//${window.location.host}`;
  return {
    successURL: `${base}/#/app/account`,
    cancelURL: `${base}/#/app/account`,
  };
};

export { getStripeRedirectUrls };
