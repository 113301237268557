import { supbaseSignInWithEmail } from '../supabase/supbaseSignInWithEmail';
import { supabaseSignInWithOtp } from '../supabase/supabaseSignInWithOtp';
import { asyncAuthFlow } from './asyncAuthFlow';
import AppConst from '../../constants/app.constant';

const doSupabaseClassicLogin = async (username, userpassword) => {
  const { error } = await supbaseSignInWithEmail({
    username,
    userpassword,
  });
  if (error) {
    return {
      message: AppConst.loginFailedMessage,
    };
  }
  await asyncAuthFlow();
};

const doSupabaseMagicLogin = async (username) => {
  const { error } = await supabaseSignInWithOtp({
    username,
  });
  if (error) {
    return {
      status: false,
      error,
    };
  }
  return { status: true };
};

const authLogin = async (username, userpassword, useMagicLink) => {
  if (!useMagicLink) {
    return doSupabaseClassicLogin(username, userpassword, useMagicLink);
  }
  return doSupabaseMagicLogin(username);
};

export { authLogin };
