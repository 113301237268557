<template>
  <ion-button
    shape="round"
    color="light"
    class="button-account-secondary"
    expand="full"
    @click="sendSupportEmail"
  >
    Contact Support
  </ion-button>
</template>
<script>
import AppConst from '../../constants/app.constant';

export default {
  methods: {
    sendSupportEmail(event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      window.open(`mailto:${AppConst.errorReporting.mailto}`, '_self');
    },
  },
};
</script>
