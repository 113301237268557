<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->

<template>
  <div class="main-container">
    <div>
      <div id="backdoor" class="main-heading">
        {{ message }}
        <div class="account-expired">
          Account: {{ userEmail }}
        </div>
      </div>
      <div>
        If you have any questions please contact us at
        <a href @click="sendSupportEmail">support@weatherspork.com</a>
      </div>
      <div class="clear-login-div" />
      <div class="clear-login-div" />
      <div>
        <IapPlaceOrderButton
          v-if="productToBuy && showInAppSubscriptionButton"
          :product-to-buy="productToBuy"
          @iap-product-purchased="onIapProductPurchased"
        />
        <ion-button
          v-else
          fill="solid"
          color="primary"
          @click="$emit('nextSlide')"
        >
          Buy Subscription
        </ion-button>
      </div>
    </div>
  </div>
</template>
<script>
import { IonButton } from '@ionic/vue';
import { mapGetters } from 'vuex';
import IapPlaceOrderButton from '../InAppPurchase/IapPlaceOrderButton.vue';
import AppConst from '../../constants/app.constant';
import { doSignoutTask } from '../../services/user/doSignoutTask';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import { USER_GETTERS_ENUM } from '../../store/modules/user/user.enum';
import { SUBSCRIPTION_GETTERS_ENUM } from '../../store/modules/subscription/subscription.enum';
import { getIsFreeTrialUser } from '../../services/user/getIsFreeTrialUser';
import { showPaypalSubscriptionOptions } from '../../services/subscription/showPaypalSubscriptionOptions';
import { getIsActiveApplePayUser } from '../../services/user/getIsActiveApplePayUser';

export default {
  name: 'NeedSubscription',
  components: {
    IonButton,
    IapPlaceOrderButton,
  },
  data() {
    return {
      clicks: 0,
    };
  },
  emits: ['nextSlide', 'go-back-to-account'],
  computed: {
    ...mapGetters(STORE_NAMESPACE.USER, {
      userDetails: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      products: SUBSCRIPTION_GETTERS_ENUM.GET_ALL_IAP_PRODUCTS,
      userPaypalSubscription:
        SUBSCRIPTION_GETTERS_ENUM.GET_USER_PAYPAL_SUBSCRIPTION,
      isInAppPurchaseActive:
        SUBSCRIPTION_GETTERS_ENUM.GET_IF_IAP_SUBSCRIPTION_IS_ACTIVE,
    }),
    message() {
      return getIsFreeTrialUser(this.userDetails)
        ? 'Your trial membership has expired'
        : 'Your membership has expired';
    },
    userEmail() {
      return this.userDetails ? this.userDetails.email : '';
    },
    productToBuy() {
      return this.products ? this.products[0] : null;
    },
    isPayPalAllowedForUser() {
      const status = this.userDetails
        && showPaypalSubscriptionOptions(
          this.userDetails,
          this.userPaypalSubscription,
          true,
        );
      return status;
    },
    showInAppSubscriptionButton() {
      if (this.isInAppPurchaseActive) {
        if (
          !getIsActiveApplePayUser(this.userDetails)
          && !this.isPayPalAllowedForUser
        ) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    console.log('mounted');
    const div = document.getElementById('backdoor');
    if (div) {
      console.log('add');
      div.addEventListener('click', this.click, false);
    }
  },
  methods: {
    onIapProductPurchased() {
      this.$emit('go-back-to-account');
    },
    onBuySubscription() {
      this.$emit('nextSlide');
    },
    sendSupportEmail(event) {
      event.preventDefault();
      window.open(`mailto:${AppConst.errorReporting.mailto}`, '_self');
    },
    click() {
      this.clicks += 1;
      if (this.clicks >= 5) {
        doSignoutTask();
      }
    },
  },
};
</script>
<style scoped>
.main-container {
  display: flex;
  height: 100%;
  width: 100%;
  color: #fff;
  margin-bottom: 10px;
}
.main-container a {
  color: #4c8dff;
  font-weight: bold;
  margin-top: 15px;
  text-decoration: none;
}
.main-container > div {
  margin: auto;
  flex-direction: column;
  display: flex;
  text-align: center;
}
.main-container .main-heading {
  margin-bottom: 10px;
  font-size: 22px;
}
.main-container ion-button {
  margin: auto;
}
.account-expired {
  font-size: 12px;
  margin-top: 3px;
}
</style>
