export default {
  dev: {
    endpoint: 'https://api-v4.weatherspork.com/',
    key: '6HcH11B0M5kqOf6gfq2dQyOZSzkRIZGH6gl1EfMHBrV6MyowtZOJAKwwI6cSKCY0C7pgBKMiMHKSaAtann6EBl3W0rXVCcmpzpfiqAhmCfSrpk5RRffXLAGN9W48O3fowaqT0GpwBWV9RJ7gRtEILexgEvzjeQxMMXUJN1BygPMEgjDzG0EjlGuQoscYeseAEO3Qt7cwuEBlNYNG8c7l',
  },
  prod: {
    endpoint: 'https://api-v4.weatherspork.com/',
    key: '6HcH11B0M5kqOf6gfq2dQyOZSzkRIZGH6gl1EfMHBrV6MyowtZOJAKwwI6cSKCY0C7pgBKMiMHKSaAtann6EBl3W0rXVCcmpzpfiqAhmCfSrpk5RRffXLAGN9W48O3fowaqT0GpwBWV9RJ7gRtEILexgEvzjeQxMMXUJN1BygPMEgjDzG0EjlGuQoscYeseAEO3Qt7cwuEBlNYNG8c7l',
  },
};
