<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div class="fav-list">
    <ion-item
      v-if="favoriteList.length == 0"
      class="item-icon-left item-accordion fav-placeholder"
    >
      <ion-label class="no-fav">
        To add a favorite, click or tap the star button in the top-left corner.
      </ion-label>
    </ion-item>
    <ion-menu-toggle auto-hide="false">
      <ion-item
        v-for="(item, index) in favoriteList"
        :key="index"
        class="fav-items"
        @click="goToFav(item)"
      >
        <font-awesome-icon
          v-if="item.fontawesome"
          slot="start"
          :icon="item.icon"
        />
        <ion-icon
          v-else
          slot="start"
          mode="md"
          :icon="iconsData[item.icon]"
        />
        <ion-label>{{ item.displayValue }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
  </div>
</template>
<script>
import {
  IonItem,
  IonIcon,
  IonLabel,
  IonMenuToggle,
} from '@ionic/vue';
import {
  globe,
  images,
  bug,
  videocam,
  calendar,
  trendingUp,
} from 'ionicons/icons';
import _forEach from 'lodash/forEach';
import { mapGetters } from 'vuex';
import AppConst from '../../constants/app.constant';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import { USER_GETTERS_ENUM } from '../../store/modules/user/user.enum';
import FavoritesService from './Favorites.service';
import utilityService from '../../services/utility.service';

export default {
  name: 'FavoritesComponent',
  components: {
    IonMenuToggle,
    IonIcon,
    IonLabel,
    IonItem,
  },
  emits: ['fav-item-clicked'],
  data() {
    return {
      iconsData: {
        globe,
        images,
        bug,
        videocam,
        calendar,
        trendingUp,
      },
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.USER, {
      user: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
    favoriteList() {
      return this.loadFav(this.user) || [];
    },
  },
  mounted() {
  },
  methods: {
    goToFav(item) {
      this.$emit('fav-item-clicked');
      FavoritesService.goToFav(item);
    },
    getStateConfigByStateName(stateName) {
      const stateKeys = Object.keys(AppConst.stateMap);
      const state = stateKeys.find((item) => AppConst.stateMap[item].key === stateName);
      return AppConst.stateMap[state];
    },
    getFavItemIcon(favItem) {
      const { icon, fontawesome } = this.getStateConfigByStateName(favItem.stateName);
      return { icon, fontawesome };
    },
    getFavItem(favItem) {
      return { ...favItem, ...this.getFavItemIcon(favItem) };
    },
    loadFav() {
      if (!this.user) {
        return [];
      }
      const listTemp = FavoritesService.getFavoriteList(this.user.favorites);
      const filterTheseViews = ['app.avwxtab', 'app.workshops'];
      const favoriteList = [];
      _forEach(listTemp, (item) => {
        if (
          item.stateName === 'app.dashboard'
          && item.params
          && AppConst.allowedAirportTabs.indexOf(item.params.actionKey) > -1
        ) {
          favoriteList.push(this.getFavItem(item));
        } else if (
          item.stateName !== 'app.dashboard'
          && filterTheseViews.indexOf(item.stateName) === -1
        ) {
          favoriteList.push(this.getFavItem(item));
        }
      });
      return utilityService.deepClone(favoriteList);
    },
  },
};
</script>
<style scoped>
.fav-items {
  transition: 0.09s all linear;
  font-size: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  --background: transparent;
}
.fav-items svg,
.fav-items ion-icon {
  font-size: 18px;
  margin-right: 0;
}
.fav-items {
  --min-height: 16px;
}
.fav-items ion-label {
  margin: 0px 8px 0px 0;
}
html:not(.plt-hybrid) .fav-items:hover {
  border-left: 4px solid #ccac00 !important;
}
.no-fav {
  margin-top: 0;
  margin-bottom: 0;
  white-space: inherit;
}
.fav-list,
.fav-list ion-item {
  background: #1C1C1C;
  --background: #1C1C1C;
}
.bn-menu .fav-list .item:hover {
  background-color: transparent;
}
</style>
