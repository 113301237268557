import { supabase } from '../../supabaseClient';
import { supabaseGetEnvValue } from './supabaseGetEnvValue';
import reportError from '../error-handler/reportError';

async function supabaseCreateCheckoutSession(stripePayload) {
  try {
    const requestPayload = supabaseGetEnvValue();
    const result = await supabase.rpc('bn_get_stripe_checkout_session', { request_payload: { ...requestPayload, stripePayload } });
    if (!result.error) {
      return result.data;
    }
    if (result.error) {
      reportError({
        message: 'Error Creating Stripe Checkout Session',
        code: 'Error Creating Stripe Checkout Session',
        name: result.error.message,
        result,
        requestPayload,
      });
    }
    return null;
  } catch (error) {
    console.error('Error getting stripe checkout session:', error.message);
    reportError(error);
  }
}

export { supabaseCreateCheckoutSession };
