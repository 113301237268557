<template>
  <IonFooter>
    <IonToolbar color="dark">
      <div id="backdoor" class="app-verison">
        <div>{{ deviceAppNameVersion }} / {{ deviceBundleVersion }}</div>
        <div>v{{ codeVersion }} / {{ codeBundleVersion }}</div>
      </div>
    </IonToolbar>
  </IonFooter>
</template>
<script>
import {
  IonToolbar,
  IonFooter,
} from '@ionic/vue';
import { getAppInfo } from '../services/capacitor/getApp.info';
import AppConst from '../constants/app.constant';
import { doSignoutTask } from '../services/user/doSignoutTask';

export default {
  components: {
    IonToolbar,
    IonFooter,
  },
  data() {
    return {
      deviceInfo: null,
      clicks: 0,
    };
  },
  computed: {
    deviceBundleVersion() {
      return this.deviceInfo && this.deviceInfo.appBuild;
    },
    deviceAppNameVersion() {
      return this.deviceInfo && `${this.deviceInfo.appName} v${this.deviceInfo.appVersion}`;
    },
    codeVersion() {
      return AppConst.application.version;
    },
    codeBundleVersion() {
      return AppConst.application.bundleVersion;
    },
  },
  mounted() {
    getAppInfo().then(({ appBuild, appVersion, appName }) => {
      this.deviceInfo = {
        appBuild: appBuild || this.codeBundleVersion,
        appVersion: appVersion || this.codeVersion,
        appName: appName || AppConst.application.name,
      };
    });
    console.log('mounted');
    const div = document.getElementById('backdoor');
    if (div) {
      console.log('add');
      div.addEventListener('click', this.click, false);
    }
  },
  methods: {
    click() {
      this.clicks += 1;
      if (this.clicks >= 5) {
        doSignoutTask();
      }
    },
  },
};
</script>
<style scoped>
.app-verison {
  text-align: center;
  color: silver;
  padding: 4px;
}
</style>
