import {
  SUBSCRIPTION_GETTERS_ENUM,
} from './subscription.enum';
import { getIsUserPaypalSubscriptionActive } from '../../../services/user/getIsUserPaypalSubscriptionActive';

export const subscriptionGetters = {
  [SUBSCRIPTION_GETTERS_ENUM.GET_ALL_PAYPAL_PLANS](state) {
    return state.allPaypalPlans;
  },
  [SUBSCRIPTION_GETTERS_ENUM.GET_ALL_STRIPE_PLANS](state) {
    return state.allStripePlans;
  },
  [SUBSCRIPTION_GETTERS_ENUM.GET_ANNUAL_PAYPAL_PLAN](state) {
    if (state.allPaypalPlans && state.allPaypalPlans.length) {
      return state.allPaypalPlans.find((plan) => plan.duration === 'YEAR');
    }
  },
  [SUBSCRIPTION_GETTERS_ENUM.GET_USER_PAYPAL_SUBSCRIPTION](state) {
    return state.paypalSubscription;
  },
  [SUBSCRIPTION_GETTERS_ENUM.GET_ACTIVE_PAYPAL_PLAN](state) {
    if (state.paypalSubscription && getIsUserPaypalSubscriptionActive(state.paypalSubscription)) {
      return state.paypalSubscription.planDetails;
    }
    return null;
  },
  [SUBSCRIPTION_GETTERS_ENUM.GET_IF_IAP_SUBSCRIPTION_IS_ACTIVE](state) {
    return state.isInAppPurchaseActive;
  },
  [SUBSCRIPTION_GETTERS_ENUM.GET_ALL_IAP_PRODUCTS](state) {
    return state.allIapProducts;
  },
};
