import { supabase } from '../../supabaseClient';

async function supabaseVerifyOtp({ otp, email }) {
  const { data, error } = await supabase.auth.verifyOtp({
    email,
    token: otp,
    type: 'email',
  });
  return { data, error };
}

export { supabaseVerifyOtp };
