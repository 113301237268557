<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
-->
<template>
  <IonPage>
    <SentinelElement />
    <ion-content id="contents" class="account login-content">
      <ion-card class="welcome-account account-theme accountmodal-spacing">
        <ion-card-content>
          <img id="backdoor" src="static/img/horizontal-logo.svg" class="side-nav-logo" />
          <div class="account-primary-form">
            <form id="loginregisterformprimary" name="loginregisterformprimary" novalidate @submit="handleSubmit">
              <div v-if="!isIphone5 && visibleViewIndex === 1">
                <h2>You'll use this email and password to login to</h2>
                <h2>WeatherSpork on your other devices.</h2>
                <h2>Register for 14 day free trial.</h2>
              </div>
              <div v-if="isIphone5 && visibleViewIndex === 1">
                <h2>You'll use this email and password to</h2>
                <h2>login to WeatherSpork on your other devices.</h2>
                <h2>Register for 14 day free trial.</h2>
              </div>
              <div v-if="visibleViewIndex === 3">
                <h2>Get a magic link sent to your email that'll sign you in instantly!</h2>
              </div>
              <br />
              <p
                v-if="
                  validation
                    && validation.message
                    && validation.message !== 'ContactSupport'
                    && validation.message !== 'UserAlreadyRegistered'
                    && validation.message !== 'Support'
                    && validation.message !== 'Subexpire'
                    && validation.message !== 'Trialexpire'
                    && validation.message.indexOf('Trialexpire:IAP') < 0
                "
                class="validationmessage"
              >
                {{ validation.message }}
              </p>
              <p v-if="validation.message === 'UserAlreadyRegistered'" class="validationmessage">
                User already registered. Please login.
                <br />Please contact
                <a href @click="sendSupportEmail">support@weatherspork.com</a>
                if you need any assistance..
              </p>
              <p v-if="validation.message === 'ContactSupport'" class="validationmessage">
                There's a problem with your account. Please contact
                <a href @click="sendSupportEmail">support@weatherspork.com</a>
                for assistance.
              </p>
              <p v-if="validation.message === 'Support'" class="validationmessage">
                Please enter the verification code <br />
                we sent to your email address.
              </p>
              <p v-if="validation.message === 'Subexpire'" class="validationmessage">
                Your subscription has expired. Please contact
                <a @click="sendSupportEmail">support@weatherspork.com</a>
                for assistance.
              </p>
              <!-- <p v-show="validation.message === 'Trialexpire'" class="validationmessage">
                Your trial membership has expired. Please contact <a href
                 v-on:click="sendSupportEmail()">support@weatherspork.com</a>
              for assistance.</p> -->
              <ion-item v-if="visibleViewIndex === 1">
                <ion-label>First Name</ion-label>
                <ion-input
                  v-model.trim="accountDetail.firstname"
                  name="firstname"
                  placeholder="Enter Your First Name"
                  @keyup.enter="accountFormSubmit"
                />
              </ion-item>
              <ion-item v-if="visibleViewIndex === 1">
                <ion-label>Last Name</ion-label>
                <ion-input
                  v-model.trim="accountDetail.lastname"
                  name="lastname"
                  placeholder="Enter Your Last Name"
                  @keyup.enter="accountFormSubmit"
                />
              </ion-item>
              <ion-item>
                <ion-label>Email</ion-label>
                <ion-input
                  id="emailTextField"
                  v-model.trim="accountDetail.useremail"
                  autocomplete="on"
                  type="email"
                  name="username"
                  placeholder="Enter Your Email"
                  @keyup.enter="visibleViewIndex == 3 ? loginViaMagicLink() : accountFormSubmit()"
                  @ionInput="accountDetail.useremail = $event.target.value.toLowerCase().trim()"
                />
              </ion-item>
              <ion-item v-if="visibleViewIndex === 1 || visibleViewIndex === 2">
                <ion-label>Password</ion-label>
                <ion-input
                  id="passwordTextField"
                  v-model="accountDetail.userpassword"
                  autocomplete="on"
                  name="password"
                  type="password"
                  placeholder="Enter Your Password"
                  @keyup.enter="accountFormSubmit"
                />
              </ion-item>
              <ion-item v-if="visibleViewIndex === 1">
                <ion-label>Confirm</ion-label>
                <ion-input
                  v-model="accountDetail.confirmpassword"
                  name="confirmpassword"
                  type="password"
                  placeholder="Confirm Your Password"
                  @keyup.enter="accountFormSubmit"
                />
              </ion-item>
              <ion-button v-show="false">
                Dummy button
              </ion-button>
              <ion-button
                v-if="visibleViewIndex == 1"
                shape="round"
                color="warning"
                expand="full"
                @click="registerNow()"
              >
                Create Account
              </ion-button>
              <div v-if="visibleViewIndex == 3">
                <ion-button
                  id="loginbutton"
                  shape="round"
                  color="warning"
                  expand="full"
                  @click="loginViaMagicLink()"
                >
                  Log in / Sign up<span class="login-spinner" v-if="showMagicLoginSpinner"><ion-spinner  name="crescent" /></span>
                </ion-button>
                <div class="clear-login-div" />
                <div class="or-divider">
                  OR
                </div>
              </div>
              <ion-button
                v-if="visibleViewIndex == 2"
                id="loginbutton"
                shape="round"
                color="warning"
                expand="full"
                @click="loginNow()"
              >
                Log in<span class="login-spinner" v-if="showLoginSpinner"><ion-spinner  name="crescent" /></span>
              </ion-button>
              <div class="clear-login-div" />
              <div v-show="visibleViewIndex === 3">
                <ion-button
                  shape="round"
                  color="light"
                  expand="full"
                  @click="changeView('classic')"
                >
                  Log in via Password
                </ion-button>
                <div class="clear-login-div" />
                <div class="clear-login-div" />
              </div>
              <div class="clear-login-div" />
              <div v-show="visibleViewIndex === 2">
                <ion-button
                  shape="round"
                  color="light"
                  expand="full"
                  @click="changeView('register')"
                >
                  Need an account? Sign up here.
                </ion-button>
              </div>
              <div v-show="visibleViewIndex === 1">
                <ion-button shape="round" color="light" expand="full" @click="changeView('login')">
                  Already have an account? Log in here.
                </ion-button>
              </div>
              <div v-show="visibleViewIndex === 2 || visibleViewIndex === 1">
                <div class="clear-login-div" />
                <ion-button shape="round" color="light" expand="full" @click="changeView('magic')">
                  Log in via Email Magic link
                </ion-button>
                <br />
              </div>
              <ContactSupportButton />
              <div class="clear-login-div" />
              <div class="clear-login-div" />
            </form>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
    <AppVersion />
  </IonPage>
</template>
<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonCard,
  IonCardContent,
  IonItem,
  IonInput,
  IonLabel,
  IonSpinner,
} from '@ionic/vue';
import { checkmarkCircle } from 'ionicons/icons';
import AppConst from '../constants/app.constant';
import AccountConst from '../constants/account.constant';
import { hideIonicLoading, showIonicLoading } from '../services/loading.service';
import ConfigDeviceSettings from '../config/device.config';
import menuService from '../services/menu.service';
import { routerPushInterface } from '../services/router.interface';
import AppVersion from '../components/AppVersion.vue';
import { doSignoutTask } from '../services/user/doSignoutTask';
import { authLogin } from '../services/auth/authLogin';
import { authRegister } from '../services/auth/authRegister';
import capacitorService from '../services/capacitor.service';
import { checkRegisterFormValid } from '../utils/checkRegisterFormValid';
import { checkLoginFormValid } from '../utils/checkLoginFormValid';
import ContactSupportButton from '../components/auth/ContactSupportButton.vue';
import { openOtpModalHandle } from '../modal/otp.modal';
import { openbackDoorgate } from '../alert/openbackDoorgate.alert';

const LoginContentHandle = {
  registerLocked: false,
  loginLocked: false,
};
export default {
  name: 'LoginForm',
  components: {
    IonContent,
    IonCard,
    IonCardContent,
    IonPage,
    IonButton,
    IonItem,
    IonInput,
    IonLabel,
    IonSpinner,
    AppVersion,
    ContactSupportButton,
  },
  data() {
    return {
      mouseTimer: null,
      showLoginSpinner: false,
      showMagicLoginSpinner: false,
      accountDetail: {
        firstname: '',
        lastname: '',
        useremail: '',
        userpassword: '',
        confirmpassword: '',
      },
      visibleViewIndex: 3,
      isIphone5: false,
      validation: {},
      checkmarkCircle,
    };
  },
  methods: {
    ionViewDidEnter() {
      const vm = this;
      if (!ConfigDeviceSettings.settings.enableLoginFlow) {
        // if login is not enabled but trying to open login screen
        routerPushInterface({ name: AppConst.defaultHomeViewState });
      }
      vm.disableEnableMenuSwipe(false);
      routerPushInterface({ query: {} });
      vm.visibleViewIndex = 3;
      vm.prePopulateLoginModel();
      setTimeout(() => {
        vm.prePopulateLoginModel();
      }, 2000);
      const div = document.getElementById('backdoor');
      if (div) {
        if (capacitorService.isCapacitorMobileApp) {
          div.addEventListener('touchstart', this.mouseDown, false);
          div.addEventListener('touchend', this.mouseUp, false);
        } else {
          div.addEventListener('mousedown', this.mouseDown);
          document.body.addEventListener('mouseup', this.mouseUp);
        }
      }
    },
    ionViewWillLeave() {
      // show split pane for other screen
      this.disableEnableMenuSwipe(true);
    },
    mouseUp() {
      if (this.mouseTimer) {
        window.clearTimeout(this.mouseTimer); // cancel timer when mouse button is released
      }
    },
    mouseDown() {
      this.mouseUp();
      // set timeout to fire in 2 seconds when the user presses mouse button down
      this.mouseTimer = window.setTimeout(openbackDoorgate, 5000);
    },
    /**
     * This method is useful if user ar pre-filled rememberd userdetail in browser
     * Then model was not getting updated
     */
    prePopulateLoginModel() {
      const vm = this;
      const emailTextField = document.querySelector('#emailTextField input');
      const passwordTextField = document.querySelector('#passwordTextField input');
      if (!vm.accountDetail.useremail && emailTextField && emailTextField.value) {
        vm.accountDetail.useremail = emailTextField.value;
      }
      if (!vm.accountDetail.userpassword && passwordTextField && passwordTextField.value) {
        vm.accountDetail.userpassword = passwordTextField.value;
      }
    },
    handleSubmit(event) {
      event.preventDefault(); // Prevent the default form submission
      console.log('Form submission prevented!', this.name);
    },
    handleDismiss(ev) {
      const { role } = ev.detail;
      console.log(`Dismissed with role: ${role}`);
    },
    async loginViaMagicLink() {
      this.validation = {};
      const email = this.accountDetail.useremail;
      const emailForLogin = email ? email.toLowerCase() : '';
      this.showMagicLoginSpinner = true;
      const data = await authLogin(emailForLogin, null, true);
      this.showMagicLoginSpinner = false;
      if (data?.status) {
        openOtpModalHandle({ email });
      } else if (data?.error) {
        this.validation = data.error;
      }
    },
    resetAccountForm() {
      this.accountDetail = {
        firstname: '',
        lastname: '',
        useremail: '',
        userpassword: '',
        confirmpassword: '',
      };
    },
    async authLogin(email, password) {
      const emailForLogin = email ? email.toLowerCase() : '';
      const data = await authLogin(emailForLogin, password);
      if (data?.message) {
        this.showLoginSpinner = false;
        this.validation = data;
      }
    },
    async registerNow() {
      const vm = this;
      if (this.checkRegisterFormValid()) {
        if (!LoginContentHandle.registerLocked) {
          LoginContentHandle.registerLocked = true;
          showIonicLoading(AccountConst.processingText.signup);
          vm.validation = {};
          vm.accountDetail.useremail = vm.accountDetail.useremail.toLowerCase();
          const data = await authRegister(vm.accountDetail);
          hideIonicLoading();
          if (data?.message) {
            this.validation = data;
          }
        }
        setTimeout(() => {
          // unlock login button after 2 seconds
          // Till then anyways overlay will be opened
          LoginContentHandle.registerLocked = false;
        }, 2000);
      }
    },
    loginNow() {
      const vm = this;
      if (vm.checkLoginFormValid()) {
        if (!LoginContentHandle.loginLocked) {
          // lock login button on click... so that user do not click it twice
          LoginContentHandle.loginLocked = true;
          this.showLoginSpinner = true;
          setTimeout(() => {
            // Timeout to make sure the model is updated
            // Before login task clean old user info if any
            doSignoutTask(true);
            vm.validation = {};
            vm.authLogin(vm.accountDetail.useremail, vm.accountDetail.userpassword);
          });
        }
        setTimeout(() => {
          // unlock login button after 2 seconds
          // Till then anyways overlay will be opened
          LoginContentHandle.loginLocked = false;
        }, 2000);
      }
    },
    sendSupportEmail(event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      window.open(`mailto:${AppConst.errorReporting.mailto}`, '_self');
    },
    clearValidationMessage() {
      this.validation = {};
    },
    changeView(view) {
      switch (view) {
        case 'classic':
          this.visibleViewIndex = 2;
          break;
        case 'register':
          this.visibleViewIndex = 1;
          break;
        case 'login':
          this.visibleViewIndex = 2;
          break;
        case 'magic':
        default:
          this.visibleViewIndex = 3;
          break;
      }
      this.resetAccountForm();
      this.clearValidationMessage();
    },
    checkLoginFormValid() {
      const vm = this;
      vm.prePopulateLoginModel();
      const validationInfo = checkLoginFormValid(vm.accountDetail);
      vm.validation = validationInfo.validation;
      return validationInfo.isValid;
    },
    checkRegisterFormValid() {
      const vm = this;
      const validationInfo = checkRegisterFormValid(vm.accountDetail);
      vm.validation = validationInfo.validation;
      return validationInfo.isValid;
    },
    accountFormSubmit() {
      const vm = this;
      if (vm.visibleViewIndex === 1 && vm.checkRegisterFormValid()) {
        vm.registerNow();
      } else if (vm.visibleViewIndex === 2 && vm.checkLoginFormValid()) {
        vm.loginNow();
      } else if (vm.visibleViewIndex === 3) {
        vm.loginViaMagicLink();
      }
    },
    disableEnableMenuSwipe(status) {
      menuService.disableEnableMenuSwipe(status);
    },
  },
};
</script>
<style scoped>
ion-content {
  height: 100%;
}
.account-primary-form ion-item {
  background: #fff;
}
ion-button.button-account-primary {
  --background: #f28b18;
}
ion-button.button-account-secondary {
  --background: #f8f8f8;
  --color: #444;
}
ion-content {
  --background: #20232c;
}
/* .isDarkContent ion-content{
  --background: #1C1C1C
} */
ion-item ion-label {
  flex: 1 !important;
}
ion-item ion-input {
  caret-color: #fff;
  flex: 2;
  caret-color: #fff;
}
ion-item {
  --highlight-background: transparent;
}
ion-card-content {
  padding: 0;
}
.skip-auth {
  color: lightgray;
  cursor: pointer;
}
.welcome-account ion-button {
  text-transform: none;
}
.welcome-container {
  display: flex;
  text-align: center;
  color: #fff;
}
.welcome-container .side-nav-logo {
  position: relative;
}
.welcome-container .account-primary-form {
  margin: auto;
  margin-top: 60px;
}
.header-md::after {
  background-image: none;
}
.header-ios ion-toolbar:last-child {
  --border-width: 0;
}
.required-field {
  color: white;
}
.magic-success-icon {
  position: relative;
  top: 2px;
}
.or-divider {
  color: gray;
}
.login-spinner {
  margin-left: 8px;
}
</style>
