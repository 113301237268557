export default {
  publishableKeys: {
    test: 'pk_test_gNpNxhmNlkSe1dfuo4ov9x4z',
    prod: 'pk_live_Al3673v7fOnmxnLDc7krEahi',
  },
  portal: {
    test: 'https://billing.stripe.com/p/login/test_7sIeXk0Lm8zrgxiaEE',
    prod: 'https://billing.stripe.com/p/login/3csaF7bvN4XS9RS3cc',
  },
  lineItems: [],
};
