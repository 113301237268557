import ConfigDeviceSettings from '../../../config/device.config';
import { getIsConnectedToInternet } from '../../connectivity/getIsConnectedToInternet';
import { asyncAuthFlow } from '../../auth/asyncAuthFlow';
import router from '../../../router';

const resumeAppAuthCheck = async () => {
  if (
    router.currentRoute.value.name !== 'app.developer'
    && ConfigDeviceSettings.settings.enableLoginFlow
    && getIsConnectedToInternet()
  ) {
    await asyncAuthFlow({ fromResumeCheck: true });
  }
};

export { resumeAppAuthCheck };
