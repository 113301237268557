const SUBSCRIPTION_ACTIONS_ENUM = {
  GET_ALL_PAYPAL_PLANS: 'GET_ALL_PAYPAL_PLANS',
  GET_ALL_STRIPE_PLANS: 'GET_ALL_STRIPE_PLANS',
  GET_USER_PAYPAL_SUBSCRIPTION: 'GET_USER_PAYPAL_SUBSCRIPTION',
  CANCEL_USER_PAYPAL_SUBSCRIPTION: 'CANCEL_USER_PAYPAL_SUBSCRIPTION',
  SET_IF_IAP_SUBSCRIPTION_IS_ACTIVE: 'SET_IF_IAP_SUBSCRIPTION_IS_ACTIVE',
  SET_ALL_IAP_PRODUCTS: 'SET_ALL_IAP_PRODUCTS',
  CLEAR_SUBSCRIPTION_ON_LOGOUT: 'CLEAR_SUBSCRIPTION_ON_LOGOUT',
};

Object.freeze(SUBSCRIPTION_ACTIONS_ENUM);

const SUBSCRIPTION_GETTERS_ENUM = {
  GET_ALL_IAP_PRODUCTS: 'GET_ALL_IAP_PRODUCTS',
  GET_ALL_PAYPAL_PLANS: 'GET_ALL_PAYPAL_PLANS',
  GET_ALL_STRIPE_PLANS: 'GET_ALL_STRIPE_PLANS',
  GET_ANNUAL_PAYPAL_PLAN: 'GET_ANNUAL_PAYPAL_PLAN',
  GET_ACTIVE_PAYPAL_PLAN: 'GET_ACTIVE_PAYPAL_PLAN',
  GET_USER_PAYPAL_SUBSCRIPTION: 'GET_USER_PAYPAL_SUBSCRIPTION',
  GET_IF_IAP_SUBSCRIPTION_IS_ACTIVE: 'GET_IF_IAP_SUBSCRIPTION_IS_ACTIVE',
};

Object.freeze(SUBSCRIPTION_GETTERS_ENUM);

const SUBSCRIPTION_MUTATIONS_ENUM = {
  SET_ALL_IAP_PRODUCTS: 'SET_ALL_IAP_PRODUCTS',
  SET_ALL_PAYPAL_PLANS: 'SET_ALL_PAYPAL_PLANS',
  SET_ALL_STRIPE_PLANS: 'SET_ALL_STRIPE_PLANS',
  SET_USER_PAYPAL_SUBSCRIPTION: 'SET_USER_PAYPAL_SUBSCRIPTION',
  SET_IF_IAP_SUBSCRIPTION_IS_ACTIVE: 'SET_IF_IAP_SUBSCRIPTION_IS_ACTIVE',
  CLEAR_SUBSCRIPTION_ON_LOGOUT: 'CLEAR_SUBSCRIPTION_ON_LOGOUT',
};

Object.freeze(SUBSCRIPTION_MUTATIONS_ENUM);

export {
  SUBSCRIPTION_ACTIONS_ENUM,
  SUBSCRIPTION_MUTATIONS_ENUM,
  SUBSCRIPTION_GETTERS_ENUM,
};
