import { getServiceHostPreference } from '../utils/getServiceHostPreference';
import { getEnv, ENVIRONMENT } from '../getEnv';

const getPaypalConfig = () => {
  const paypalConfig = {
    local: {
      clientId: 'AfTD1z6GD1gShW5d1GXsxbmNU9gcvKEJeh58-EOzeEninxiqOADnPu8a05mVVT8uo0sg7omrLP2UbrQm',
      serviceUrl: 'https://dfw.weatherspork.com/iap-dev/',
    },
    dev: {
      clientId: 'AfTD1z6GD1gShW5d1GXsxbmNU9gcvKEJeh58-EOzeEninxiqOADnPu8a05mVVT8uo0sg7omrLP2UbrQm',
      serviceUrl: `${getServiceHostPreference()}/iap-dev/`,
    },
    prod: {
      clientId: 'AXvbiinS95SUyQDk3adBPPHqCF-4QQIZbeWQFCdKjStZT6mxLD4QR8QAQZ1698ix0xFY4efdqSbU1wiI',
      serviceUrl: `${getServiceHostPreference()}/iap/`,
    },
  };
  const env = getEnv();
  if (env === ENVIRONMENT.TEST) {
    return paypalConfig.dev;
  }
  if (env === ENVIRONMENT.LOCAL) {
    return paypalConfig.local;
  }
  return paypalConfig.prod;
};

export { getPaypalConfig };
