import {
  getKeyValueFromLocalStorage,
} from '../../localstorage/getKeyValueFromLocalStorage';
import AppConst from '../../../constants/app.constant';

const getTuValue = () => {
  let tu = getKeyValueFromLocalStorage(AppConst.localstorageConf.tu);
  if (!tu) {
    tu = new Date().toISOString();
  }
  return tu;
};

export { getTuValue };
