import StripeConst from '../../constants/stripe.constant';
import { getEnv, ENVIRONMENT } from '../getEnv';

const getStripePublishKey = () => {
  const env = getEnv();
  if (env === ENVIRONMENT.TEST || env === ENVIRONMENT.LOCAL) {
    return StripeConst.publishableKeys.test;
  }
  return StripeConst.publishableKeys.prod;
};

export { getStripePublishKey };
