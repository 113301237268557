/**
 * Default mock data
 */
// const mockDataForIapProduct = [{
//   alias: 'monthly',
//   canPurchase: true,
//   countryCode: 'IN',
//   currency: 'INR',
//   description: 'Monthly subscription',
//   id: 'weathersporkplus',
//   loaded: true,
//   owned: false,
//   price: '₹ 1,599.00',
//   state: 'valid',
//   title: 'WeatherSpork Plus',
//   type: 'non renewing subscription',
//   valid: true,
// }];

export const subscriptionState = {
  allIapProducts: null,
  allPaypalPlans: null,
  allStripePlans: null,
  paypalSubscription: null,
  isPaypalSubscriptionAllowed: false,
  isIapSubscriptionAllowed: false, // this decides business logic
  isInAppPurchaseActive: false, // this tell is iOS platoform
};
