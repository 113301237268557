import { supabaseUpdateUser } from '../../../supabase/supabaseUpdateUser';
import { getTuValue } from '../../utils/getTuValue';

const setAppInstallDate = async () => supabaseUpdateUser({
  rpc: 'bn_update_app_install_date',
  data: {
    app_install_date: getTuValue(),
  },
});

export { setAppInstallDate };
