<template>
  <ion-list>
    <div>
      <div class="custom-divider">
        Membership
      </div>
      <div>
        <ion-item>
          <ion-label>Expiration date (Free Trial)</ion-label>
          <ion-label
            slot="end"
            class="right-align expiryinfo"
          >
            {{ freeTrialExpiryDate }}
          </ion-label>
        </ion-item>
      </div>
    </div>
  </ion-list>
</template>
<script>
import {
  IonList, IonItem, IonLabel,
} from '@ionic/vue';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import AppConst from '../constants/app.constant';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import { USER_GETTERS_ENUM } from '../store/modules/user/user.enum';
import { getFreeTrialExpiresOn } from '../services/user/getFreeTrialExpiresOn';

export default {
  components: {
    IonList,
    IonItem,
    IonLabel,
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.USER, {
      user: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
    freeTrialExpiryDate() {
      const d = getFreeTrialExpiresOn(this.user);
      if (d) {
        return format(new Date(d), AppConst.buserPreferredDatedFormat);
      }
      return undefined;
    },
  },
};
</script>
