import ConfigDeviceSettings from '../config/device.config';
import utilityService from './utility.service';

const ENVIRONMENT = {
  TEST: 'test',
  PROD: 'prod',
  LOCAL: 'local',
};

const getEnv = () => {
  if (utilityService.isCordovaApplication()) {
    if (ConfigDeviceSettings.settings.enableDeveloperMode) {
      return ENVIRONMENT.TEST;
    }
    return ENVIRONMENT.PROD;
  }
  if (window.location.hostname === 'app.weatherspork.com') {
    return ENVIRONMENT.PROD;
  }
  if (window.location.hostname === 'localhost') {
    return ENVIRONMENT.LOCAL;
  }
  return ENVIRONMENT.TEST;
};

export { ENVIRONMENT, getEnv };
