import { supabaseSetSession } from '../supabase/supabaseSetSession';

const getTokensFromUrl = (url) => {
  // Extract the fragment part of the URL
  const fragment = (url || window.location.href).split('#')[1];

  // Extract the query parameters from the fragment
  const params = new URLSearchParams(fragment);

  // Create an object to hold the extracted parameters
  const queryParams = {};

  // Iterate through each parameter and store it in the queryParams object
  params.forEach((value, key) => {
    queryParams[key.replace('/', '')] = value;
  });
  console.log(queryParams);
  return queryParams;
};

const doMagicLinkLogin = async (url) => {
  const tokens = getTokensFromUrl(url);
  if (tokens && tokens.type === 'magiclink') {
    const data = await supabaseSetSession(tokens);
    console.log(data);
  }
};

export { doMagicLinkLogin };
