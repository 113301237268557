import { supabase } from '../../supabaseClient';

async function supbaseSignUpNewUser(email, password, options) {
  return supabase.auth.signUp({
    email,
    password,
    options: {
      ...options,
    },
  });
}

export { supbaseSignUpNewUser };
