import { supabaseUpdateUser } from '../../../supabase/supabaseUpdateUser';

const setEulaAgreed = async (version) => supabaseUpdateUser({
  rpc: 'bn_update_eula_info',
  data: {
    eula_version: version.toString(),
    eula_agree_date: new Date().toISOString(),
  },
});

export { setEulaAgreed };
