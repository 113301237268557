<template>
  <div>
    Hello User
  </div>
</template>
<script>
import {
  modalController,
} from '@ionic/vue';
import { supabase } from '../supabaseClient';

export default {
  async created() {
    // Capture URL parameters
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      // Complete the login process with Supabase
      const { error } = await supabase.auth.signInWithIdToken({ token });
      console.log(error);
      if (error) {
        console.error('Error completing login:', error.message);
      } else {
        // this is for OTP modal to be dismissed
        modalController.dismiss();
        console.log('Login successful');
        // Redirect the user to another page
        // this.$router.push('/dashboard');
      }
    } else {
      console.error('No token found in URL');
    }
  },
};
</script>
