import StripeConst from '../../constants/stripe.constant';
import { getEnv, ENVIRONMENT } from '../getEnv';

const getStripePortal = () => {
  const env = getEnv();
  if (env === ENVIRONMENT.TEST || env === ENVIRONMENT.LOCAL) {
    return StripeConst.portal.test;
  }
  return StripeConst.portal.prod;
};

export { getStripePortal };
