import { supabase } from '../../supabaseClient';

async function supabaseSetSession({ access_token, refresh_token }) {
  return supabase.auth.setSession({
    access_token,
    refresh_token,
  });
}

export { supabaseSetSession };
