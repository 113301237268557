<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div
    class="ion-page"
  >
    <ion-header>
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <ion-button
            fill="solid"
            color="secondary"
            @click="closeModal(false)"
          >
            Close
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button
            :disabled="routesObject[0].valid == false"
            fill="solid"
            color="secondary"
            @click="closeModal(true)"
          >
            Apply
          </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content id="contents">
      <ion-list class="airport-selector-list">
        <ion-item
          v-for="(item, $index) in routesObject"
          :key="$index"
          class="airport-selector-input"
          @click.stop="airportFocusedMobile(item)"
        >
          <ion-label class="selector-label">
            Airport
          </ion-label>
          <ion-label
          >
            <label v-if="item.value">{{ item.value.toUpperCase() }}</label>
            <span v-if="!item.value"> Airport name/ICAO code</span>
          </ion-label>
          <ion-icon
            slot="start"
            :icon="location"
          />
          <ion-icon
            v-show="item.valid == false"
            slot="end"
            class="invalid-value"
            :icon="closeCircle"
          />
          <ion-icon
            v-show="item.valid == true"
            slot="end"
            class="valid-value"
            :icon="checkmarkCircle"
          />
          <ion-icon
            v-show="item.value && item.value.length"
            slot="end"
            :icon="trash"
            @click.stop="clearInput(item)"
          />
        </ion-item>
      </ion-list>
      <div
        v-show="routesObject[0].valid == false"
        class="alert alert-danger shake"
      >
        <p><strong>Airport</strong> is a required field</p>
      </div>
      <div v-if="viewName === 'app.dashboard'">
        <ion-radio-group
          v-model="product"
        >
          <div class="custom-divider">
            Forecast window
          </div>
          <ion-item
            v-for="(productItem, $index) in productsList"
            :key="$index"
          >
            <ion-label>{{ productItem.display_name }}</ion-label>
            <ion-radio
              slot="end"
              :value="productItem.name"
            />
          </ion-item>
        </ion-radio-group>
        <ion-radio-group
          v-model="maximumAltitudeAllowed"
        >
          <div class="custom-divider">
            Maximum Altitude
          </div>
          <ion-item
            v-for="option in altitudeOptions"
            :key="option.feet"
          >
            <ion-label>
              {{ option.text }}
            </ion-label>
            <ion-radio
              slot="end"
              :value="option.feet"
            />
          </ion-item>
        </ion-radio-group>
      </div>
    </ion-content>
  </div>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonLabel,
  IonIcon,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonInput,
  modalController,
} from '@ionic/vue';
import {
  closeCircle,
  checkmarkCircle,
  location,
  time,
  trash,
} from 'ionicons/icons';
import router from '../router';
import AirportSelector from './AirportSelector.vue';
import applicationDataSource from '../datasource/applicationdata.datasource';
import searchHistoryService from '../services/searchHistory.service';
import utilityService from '../services/utility.service';
import capacitorService from '../services/capacitor.service';
import ChartConst from '../constants/chart.constant';
import * as AltitudeOptionsConst from '../constants/altitude.constant';

export default {
  name: 'AirportForm',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonLabel,
    IonIcon,
    IonItem,
    IonList,
    IonRadio,
    IonRadioGroup,
    IonInput,
  },
  props: {
    settings: {
      type: Object,
      default: null,
    },
    eventOnClose: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      closeCircle,
      checkmarkCircle,
      location,
      time,
      trash,
      start: '',
      product: '',
      searchingText: '',
      maximumAltitudeAllowed: '',
      showRecentSearch: false,
      productsList: [
        ChartConst.products.twelvehours,
        ChartConst.products.lamp,
        ChartConst.products.mav,
      ],
      altitudeOptions: AltitudeOptionsConst.meteogram,
      selected: '',
      viewName: '',
      showingSelectorModal: false,
      isIos: capacitorService.isIos,
      focusedOn: {},
      suggestions: applicationDataSource.airportsListing,
      routesObject: [{ value: '', valid: false }],
    };
  },
  mounted() {
    const vm = this;
    vm.viewName = router.currentRoute.value.name;
    searchHistoryService.loadAirportHistorySearch();
    vm.start = vm.settings.start;
    vm.product = vm.settings.product;
    vm.routesObject[0].value = vm.settings.start;
    if (vm.settings.start) {
      vm.routesObject[0].valid = true;
    }
    // this.$refs.airportSelectorRef.searchInput = vm.start;
    vm.maximumAltitudeAllowed = vm.settings.maximumAltitudeAllowed;
  },
  methods: {
    closeModal(isSave) {
      const vm = this;
      let returnData = false;
      const formSetting = {};
      if (isSave) {
        if (vm.routesObject[0].valid) {
          formSetting.start = vm.routesObject[0].value.toUpperCase();
          formSetting.product = vm.product;
          formSetting.maximumAltitudeAllowed = parseInt(
            vm.maximumAltitudeAllowed,
            10,
          );
          returnData = formSetting;
        }
      }
      modalController.dismiss(returnData);
    },
    clearInput(item) {
      item.value = '';
      item.valid = false;
    },
    openAirportSelector() {
      const vm = this;
      if (vm.showingSelectorModal) {
        return;
      }
      vm.showingSelectorModal = true;
      modalController
        .create({
          component: AirportSelector,
          backdropDismiss: false,
          componentProps: {
            selectorInput: vm.focusedOn.value,
            isSubModal: true,
          },
        })
        .then((modalElement) => {
          modalElement.present();
          modalElement.onDidDismiss().then((res) => {
            vm.showingSelectorModal = false;
            const formData = res.data;
            if (formData) {
              vm.focusedOn.value = formData.icao_id;
            }
            vm.changingAirport(vm.focusedOn);
          });
        });
    },
    airportFocusedMobile(item) {
      const vm = this;
      vm.focusedOn = item;
      vm.openAirportSelector();
    },
    changingAirport(item) {
      const vm = this;
      // item.value = item.value
      //     ? item.value.toUpperCase()
      //     : "";
      if (!item.value) {
        item.valid = false;
      }
      if (!item.value || item.value.length < 2 || !vm.focusedOn.value) {
        return;
      }
      const filteredData = vm.suggestions.filter((option) => {
        if (!option.icao_id) {
          return false;
        }
        return (
          option.icao_id.toLowerCase().indexOf(item.value.toLowerCase()) > -1
          || option.faa_facility_name
            .toLowerCase()
            .indexOf(item.value.toLowerCase()) > -1
        );
      });
      if (item.value.length === 4) {
        const matchedResult = utilityService.getMatchedAirportObject(
          item.value.toUpperCase(),
        );
        if (matchedResult) {
          item.valid = true;
        }
      } else {
        item.valid = false;
      }
    },
    selectedItem(item) {
      const vm = this;
      vm.focusedOn.value = item.icao_id;
      vm.focusedOn.valid = true;
      vm.focusedOn = {};
    },
  },
};
</script>
