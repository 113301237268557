import {
  SUBSCRIPTION_MUTATIONS_ENUM,
} from './subscription.enum';

export const subscriptionMutations = {
  [SUBSCRIPTION_MUTATIONS_ENUM.SET_ALL_PAYPAL_PLANS](state, payload) {
    state.allPaypalPlans = payload;
  },
  [SUBSCRIPTION_MUTATIONS_ENUM.SET_ALL_STRIPE_PLANS](state, payload) {
    state.allStripePlans = payload;
  },
  [SUBSCRIPTION_MUTATIONS_ENUM.SET_USER_PAYPAL_SUBSCRIPTION](state, payload) {
    state.paypalSubscription = payload;
  },
  [SUBSCRIPTION_MUTATIONS_ENUM.SET_IF_IAP_SUBSCRIPTION_IS_ACTIVE](state, payload) {
    state.isInAppPurchaseActive = payload;
  },
  [SUBSCRIPTION_MUTATIONS_ENUM.CLEAR_SUBSCRIPTION_ON_LOGOUT](state) {
    state.paypalSubscription = null;
  },
  [SUBSCRIPTION_MUTATIONS_ENUM.SET_ALL_IAP_PRODUCTS](state, payload) {
    state.allIapProducts = payload;
  },
};
