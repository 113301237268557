import AppConst from '../../../../constants/app.constant';
import { getAppInfo } from '../../../capacitor/getApp.info';
import { supabaseUpdateUser } from '../../../supabase/supabaseUpdateUser';
// todo: check if we still need this
// import { getVersionUsageValueToSet } from '../../utils/getVersionUsageValueToSet';

const setAppVersionUsed = async () => {
  const { appVersion } = await getAppInfo();
  await supabaseUpdateUser({
    rpc: 'bn_update_version_usage',
    data: {
      app_version: AppConst.application.version,
      app_flow_version: `${appVersion || AppConst.application.version}/${AppConst.application.bundleVersion}`,
      // version_usage: await getVersionUsageValueToSet(),
    },
  });
};

export { setAppVersionUsed };
