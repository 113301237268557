<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div class="ion-page">
    <ion-header>
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <ion-button fill="solid" color="secondary" @click="closeModal()">
            Close
          </ion-button>
        </ion-buttons>
        <ion-title>OTP Verification</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content id="contents">
      <div class="otp-wrapper">
        <p v-if="validation.message" class="validationmessage">
          {{ validation.message }}
        </p>
        <img class="otp-logo" src="static/img/logo.png" />
        <div>
          A magic link has been sent. Please check your email to access
          Weatherspork.
        </div>
        <div class="or-divider">
          OR
        </div>
        <div>
          <div>
            Please enter the code sent to your email below.
          </div>

          <div class="otp-container">
            <ion-input
              id="input2otp1"
              v-model="otpValue"
              fill="outline"
              maxlength="6"
              minlength="1"
              placeholder="000000"
              inputmode="numeric"
              pattern="[0-9]*"
            />
          </div>
          <div class="otp-buttons-container">
            <ion-button
              :disabled="otpValue.length < 6"
              color="light"
              expand="block"
              @click="verifyOTP"
            >
              Verify
            </ion-button>
            <br />
            <ion-button
              :disabled="countDown > 0"
              color="light"
              expand="block"
              @click="resendOtp"
            >
              Resend
              <span v-if="countDown > 0">. please wait ({{ countDown }}) seconds</span>
            </ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </div>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonInput,
  modalController,
} from '@ionic/vue';
import { asyncAuthFlow } from '../services/auth/asyncAuthFlow';
import { supabaseVerifyOtp } from '../services/supabase/supabaseVerifyOtp';
import { authLogin } from '../services/auth/authLogin';

const RESEND_TIMER_SECONDS = 60;
let countDownTimer = null;

export default {
  name: 'OtpComponent',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonInput,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      otpValue: '',
      validation: {},
      countDown: RESEND_TIMER_SECONDS,
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      clearInterval(countDownTimer);
      this.countDown = RESEND_TIMER_SECONDS;
      countDownTimer = setInterval(() => {
        this.$nextTick(() => {
          this.countDown -= 1;
          if (this.countDown === 0) {
            this.clearTimer();
          }
        });
      }, 1100);
    },
    clearTimer() {
      clearInterval(countDownTimer);
    },
    async resendOtp() {
      const data = await authLogin(this.email, null, true);
      if (data?.error) {
        this.validation = data.error;
      } else {
        this.startCountdown();
      }
    },
    async verifyOTP() {
      const vm = this;
      this.validation = {};
      const { error } = await supabaseVerifyOtp({
        email: this.email,
        otp: this.otpValue,
      });
      if (!error) {
        this.clearTimer();
        await asyncAuthFlow();
        vm.closeModal();
      } else {
        this.validation = error;
      }
    },
    closeModal() {
      this.clearTimer();
      modalController.dismiss();
    },
  },
};
</script>
<style scoped>
.otp-wrapper {
  text-align: center;
  padding: 20px;
}
.otp-message {
  font-size: 18px;
}
.otp-logo {
  border-radius: 20px;
  max-width: 80px;
  margin-bottom: 20px;
}
.otp-container {
  max-width: 240px;
  border: 1px solid #fff;
  border-radius: 10px;
  font-size: 40px;
  text-align: center;
  margin: 10px auto;
}
.eula {
  padding: 10px;
  font-size: 14px;
}
.otp-buttons-container {
  padding: 10px;
}
.or-divider {
  color: gray;
  margin: 10px 0;
}
.please-wait-otp {
  padding: 0 2px;
}
</style>
