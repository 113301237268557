import { supabase } from '../../supabaseClient';
import { supabaseGetEnvValue } from './supabaseGetEnvValue';
import reportError from '../error-handler/reportError';

async function supabaseGetAllStripeProducts() {
  try {
    const requestPayload = supabaseGetEnvValue();
    const result = await supabase.rpc('bn_get_all_products', { request_payload: requestPayload });
    if (!result.error) {
      return result.data.map((item) => ({
        ...item,
        duration: item.recurring_interval.toUpperCase(),
      }));
    }
    if (result.error) {
      reportError({
        message: 'Error getting stripe products',
        code: 'Error getting stripe products',
        name: result.error.message,
        result,
        requestPayload,
      });
    }
    return null;
  } catch (error) {
    console.error('Error getting profile data:', error.message);
    reportError(error);
  }
}

export { supabaseGetAllStripeProducts };
