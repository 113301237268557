import { supabaseUpdateUser } from '../../../supabase/supabaseUpdateUser';

const setPayPalOrderRecord = async (paypalOrderData) => {
  await supabaseUpdateUser({
    rpc: 'bn_update_paypal_subscription',
    data: {
      paypal_sub_details: paypalOrderData,
    },
  });
};
export { setPayPalOrderRecord };
