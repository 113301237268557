import AccountConst from '../constants/account.constant';
import utilityService from '../services/utility.service';

const checkLoginFormValid = (accountDetail) => {
  if (
    accountDetail.useremail.length > 0
    && accountDetail.userpassword.length > 0
    && utilityService.validateEmail(accountDetail.useremail)
  ) {
    return { isValid: true, validation: {} };
  }
  let validation = {};
  if (accountDetail.useremail.length === 0 || accountDetail.userpassword.length === 0) {
    validation = {
      message: AccountConst.emailAndPasswordRequired,
    };
  } else if (!utilityService.validateEmail(accountDetail.useremail)) {
    validation = {
      message: AccountConst.emailValidRequired,
    };
  }
  return { isValid: false, validation };
};
export { checkLoginFormValid };
