/**
   * User is free trial if he does not following attributes
   * t1, t3, fs, p1,
   * And user has following attribute
   * trialYN
*/
const getIsFreeTrialUser = (userDetails) => {
  if (userDetails
    && !userDetails.fs
    && !userDetails.t1
    && !userDetails.t3
    && !userDetails.p1
    // DAB 24 Aug 2024: TODO: Talk to ST about whether this is needed
    // && (userDetails.trialYN === 'Y'
    //   || userDetails.trialYN === 'N')
  ) {
    return true;
  }
  return false;
};

export {
  getIsFreeTrialUser,
};
