<template>
  <div>
    <AccountPaypalMembership
      v-if="showPaypalMembership"
    />
    <AccountStripeMembership
      v-else-if="showStripeMembership"
    />
    <AccountFsMembership
      v-else-if="showFsMembership"
    />
    <AccountAppleIapMembership
      v-else-if="showAppleIapMembership"
    />
    <AccountFreeTrialMembership
      v-else-if="showFreeTrialMembership"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import { SUBSCRIPTION_GETTERS_ENUM } from '../store/modules/subscription/subscription.enum';
import { USER_GETTERS_ENUM } from '../store/modules/user/user.enum';
import AccountPaypalMembership from './AccountPaypalMembership.vue';
import AccountAppleIapMembership from './AccountAppleIapMembership.vue';
import AccountFsMembership from './AccountFsMembership.vue';
import AccountStripeMembership from './AccountStripeMembership.vue';
import AccountFreeTrialMembership from './AccountFreeTrialMembership.vue';
import { getIsActiveFsUser } from '../services/user/getIsActiveFsUser';
import { getIsActiveApplePayUser } from '../services/user/getIsActiveApplePayUser';
import { getIsUserStripeSubscriptionActive } from '../services/user/getIsUserStripeSubscriptionActive';
import { checkIsFreeTrialExpired } from '../services/user/checkIsFreeTrialExpired';

export default {
  components: {
    AccountAppleIapMembership,
    AccountPaypalMembership,
    AccountFsMembership,
    AccountStripeMembership,
    AccountFreeTrialMembership,
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      userPaypalSubscription:
        SUBSCRIPTION_GETTERS_ENUM.GET_USER_PAYPAL_SUBSCRIPTION,
    }),
    ...mapGetters(STORE_NAMESPACE.USER, {
      userDetails: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
    showPaypalMembership() {
      return this.userPaypalSubscription && this.userPaypalSubscription.id;
    },
    showStripeMembership() {
      return getIsUserStripeSubscriptionActive(this.userDetails);
    },
    showFsMembership() {
      return getIsActiveFsUser(this.userDetails);
    },
    showAppleIapMembership() {
      return getIsActiveApplePayUser(this.userDetails);
    },
    showFreeTrialMembership() {
      const isFreeTrialUser = !checkIsFreeTrialExpired(this.userDetails);
      return isFreeTrialUser;
    },
  },
};
</script>
