<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->

<template>
  <IonApp>
    <IonSplitPane
      content-id="main-content"
      :disabled="activeItem === 'app.login'"
      :when="expandWhen"
    >
      <MenuComponent
        :device-settings="deviceSettings"
        :active-item="activeItem"
      />
      <ion-router-outlet id="main-content" />
    </IonSplitPane>
    <LoadingComponent :loading-message="loadingMessage" />
  </IonApp>
</template>
<script>
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import { STORE_NAMESPACE } from './store/modules/store.namespace';
import { MESSAGE_LOADER_GETTERS_ENUM } from './store/modules/message-loader/message-loader.eum';
import EventConst from './constants/event.constant';
import Emitter from './bus';
import AppConst from './constants/app.constant';
import bnd3Service from './services/bnd3.service';
import MenuComponent from './components/Menu.vue';
import LoadingComponent from './components/Loading.vue';
import ConfigDeviceSettings from './config/device.config';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    LoadingComponent,
    MenuComponent,
  },
  data() {
    return {
      deviceSettings: ConfigDeviceSettings.settings,
      expandWhen: AppConst.splitPaneWhen,
      activeItem: '',
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.MESSAGE_LOADER, {
      loadingMessage: MESSAGE_LOADER_GETTERS_ENUM.GET_LOADING_MESSAGE,
    }),
  },
  watch: {
    $route(to) {
      this.activeItem = to.name;
    },
  },
  async created() {
    Emitter.on(EventConst.deviceSettingChanged, () => {
      this.deviceSettings = ConfigDeviceSettings.settings;
    });
  },
  mounted() {
    // supabase.auth.onAuthStateChange(async (_, _session) => {
    //   console.log('@@@@', _, _session);
    //   // if (_ === 'SIGNED_IN') {
    //   //   await asyncAuthFlow();
    //   // }
    //   // alert('hereeee', JSON.stringify(_session));
    // });

    bnd3Service.hideTrendLineOnClickOutside();
    this.activeItem = this.$route.name;
  },
});
</script>
