import { supabaseUpdateUser } from '../../../supabase/supabaseUpdateUser';

const setAirportSearchHistory = async (airport_search_history) => {
  await supabaseUpdateUser({
    rpc: 'bn_update_airport_search_history',
    data: {
      airport_search_history,
    },
  });
};

export { setAirportSearchHistory };
