import { getEnv, ENVIRONMENT } from '../getEnv';

const supabaseGetEnvValue = () => {
  const env = getEnv();
  if (env === ENVIRONMENT.LOCAL || env === ENVIRONMENT.TEST) {
    return { env: 'test' };
  }
  return {};
};

export { supabaseGetEnvValue };
