import AccountConst from '../constants/account.constant';
import utilityService from '../services/utility.service';

const checkRegisterFormValid = (accountDetail) => {
  if (
    !accountDetail.firstname.length
    || !accountDetail.lastname.length
    || !accountDetail.useremail.length
    || !accountDetail.userpassword.length
    || !accountDetail.confirmpassword.length
  ) {
    return {
      isValid: false,
      validation: {
        message: AccountConst.allRequiredField,
      },
    };
  }
  if (!utilityService.validateEmail(accountDetail.useremail)) {
    return {
      isValid: false,
      validation: {
        message: AccountConst.emailValidRequired,
      },
    };
  }
  if (accountDetail.userpassword.length < 6) {
    return {
      isValid: false,
      validation: {
        message: AccountConst.passwordMinLengthRequired,
      },
    };
  }
  if (accountDetail.userpassword !== accountDetail.confirmpassword) {
    return {
      isValid: false,
      validation: {
        message: AccountConst.registerPasswordNotMatch,
      },
    };
  }
  return { isValid: true, validation: {} };
};
export { checkRegisterFormValid };
