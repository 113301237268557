import {
  supabaseChangePassword,
} from '../../supabase/supabaseChangePassword';

const changeOldPassword = async ({
  newPassword,
}, user) => supabaseChangePassword({
  userId: user.id,
  newPassword,
});

export {
  changeOldPassword,
};
