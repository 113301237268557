import { supabaseUpdateUser } from '../../../supabase/supabaseUpdateUser';

const setUserFavroites = async (favorites) => {
  await supabaseUpdateUser({
    rpc: 'bn_update_favorites',
    data: {
      favorites,
    },
  });
};

export { setUserFavroites };
