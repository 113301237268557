<template>
  <div>
    <ion-header v-if="showHeader">
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <ion-button
            class="back-button"
            fill="solid"
            color="secondary"
            @click="onGoBackToAccount()"
          >
            Account
            <ion-icon slot="start" mode="ios" :icon="arrowBack" />
          </ion-button>
        </ion-buttons>
        <ion-title>
          Purchase Plan
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-list>
      <ion-item>
        <ion-label>Plan</ion-label>
        <ion-label slot="end" class="right-align">
          {{ productToBuy.title }}
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>Price</ion-label>
        <ion-label slot="end" class="right-align">
          {{ productToBuy.price }}
        </ion-label>
      </ion-item>
    </ion-list>
    <div>
      <!-- <div v-if="hasStripePlans" class="please-choose-payment">
        Please choose a payment option below
      </div> -->
      <div v-if="isPaypalProduct" class="pay-buttons">
        <StripeCheckout v-if="hasStripePlans" :product-to-buy="productToBuy" />
        <!-- <div v-if="hasStripePlans" class="or-divider">
          OR
        </div>
        <PaypalButton
          class="paypal-single-button"
          :product-id="productToBuy.productId"
          @paypal-product-purchased="onPaypalProductPurchased"
        /> -->
      </div>
      <IapPlaceOrderButton
        v-else
        :product-to-buy="productToBuy"
        @iap-product-purchased="onIapProductPurchased"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  IonHeader,
  IonList,
  IonItem,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonLabel,
} from '@ionic/vue';
import { arrowBack } from 'ionicons/icons';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import { SUBSCRIPTION_GETTERS_ENUM } from '../store/modules/subscription/subscription.enum';
// import PaypalButton from './PaypalSubscription/PaypalButton.vue';
import StripeCheckout from './StripeCheckbout.vue';
import IapPlaceOrderButton from './InAppPurchase/IapPlaceOrderButton.vue';

export default {
  components: {
    IonHeader,
    IonList,
    IonItem,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonLabel,
    // PaypalButton,
    IapPlaceOrderButton,
    StripeCheckout,
  },
  props: {
    productToBuy: {
      type: Object,
      default: null,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['go-back-to-account'],
  data() {
    return {
      arrowBack,
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      stipePlans: SUBSCRIPTION_GETTERS_ENUM.GET_ALL_STRIPE_PLANS,
    }),
    hasStripePlans() {
      return this.stipePlans?.length;
    },
    isPaypalProduct() {
      return this.productToBuy.isPaypalProduct;
    },
  },
  methods: {
    onGoBackToAccount() {
      this.$emit('go-back-to-account');
    },
    onPaypalProductPurchased() {
      this.onGoBackToAccount();
    },
    onIapProductPurchased() {
      this.onGoBackToAccount();
    },
  },
};
</script>
