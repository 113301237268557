export default {
  environments: {
    prod: {
      key: 'production',
      url: 'https://app.weatherspork.com',
    },
  },
  newMetarApiA: '/bnforecast/service/all-metar',
  newMetarAirportApi: '/bnforecast/service/metar?stid=',
  googleMapsApi: 'https://maps.googleapis.com/maps/api/js?key=',
  airportsListingSource: 'static/json/airports.json',
  radarSiteSource: 'static/json/nexRadSites.json',
  weatherApi: '/api/apiweathergov/',
  bnforecastMet: '/bnforecast/service/met',
  forecastWeatherGov: '/api/forecastweathergov/',
  aviationMetEndPoint: 'gis/scripts/',
  aviationMetGairmet: 'GairmetJSON.php?fore=',
  serviceEndPoint: '/bnforecast/service/route?',
  mapMosService: '/bnforecast/service/map-mos?',
  allMosApi: '/api/all-mos.json',
  gribEndPoint: '/api/grib/get-grib?',
  imageryDataSource: 'static/json/imagery.json',
  jsonBlobAPI: 'https://jsonblob.com/api/jsonBlob',
  tafsTabData: 'api/taf',
  radarRasterSourceBase: 'api/mrms/rala/raster',
  stormTrackData: 'api/st/storm_data_final.json',
  sl: {
    part1: 'T1E5SBPBR',
    part2: 'B0579LKE3GR',
    part3: '7N8rIbHv9SDYMKKX3xWBEm9L',
  },
  skewTTabsData: 'api/skew-t/?data_source=Op40&latest=latest&n_hrs=10&fcst_len=shortest&airport=',
  fcstTabData: (nws) => `https://forecast.weather.gov/product.php?site=${nws}&issuedby=${nws}&product=AFD&format=TXT&version=1&glossary=0&highlight=on`,
};
