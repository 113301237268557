import differenceInDays from 'date-fns/differenceInDays';
import { getIsActiveFsUser } from './getIsActiveFsUser';
import utilityService from '../utility.service';
import reportError from '../error-handler/reportError';
import { getFsExpiresInDays } from './getFsExpiresInDays';

const showSubscriptionExpireSoon = (user) => {
  try {
    const isActiveFsUser = getIsActiveFsUser(user);
    const applePayUser = user.t2;
    const payPalUser = user.t3;
    const lastReminderOn = utilityService.getSubscriptionExpireReminderFromLocalStorage();
    if (isActiveFsUser && !applePayUser && !payPalUser) {
      const userFsValue = user.fs;
      const fsExpireInDays = getFsExpiresInDays(userFsValue);
      // eslint-disable-next-line max-len
      const lastReminderInDays = lastReminderOn
        ? differenceInDays(new Date(), new Date(lastReminderOn))
        : 30;
      if (fsExpireInDays < 45) {
        if (lastReminderInDays > 7) {
          return true;
        }
      }
    }
    return false;
  } catch (ex) {
    console.log('showSubscriptionExpireSoon', ex);
    reportError(ex);
    return false;
  }
};

export { showSubscriptionExpireSoon };
