import {
  store,
} from '../../store/store';
import {
  STORE_NAMESPACE,
} from '../../store/modules/store.namespace';
import {
  SUBSCRIPTION_ACTIONS_ENUM,
} from '../../store/modules/subscription/subscription.enum';

/**
 *
 * @returns Promise
 */
const getAllStripePlans = async () => store.dispatch(
  `${STORE_NAMESPACE.SUBSCRIPTION}/${SUBSCRIPTION_ACTIONS_ENUM.GET_ALL_STRIPE_PLANS}`,
);

export {
  getAllStripePlans,
};
