import { supabaseCreateCheckoutSession } from '../supabase/supabaseCreateCheckoutSession';

const createCheckoutSession = async ({
  email, lineItem, redirects,
}) => {
  const body = new URLSearchParams({
    'line_items[0][price]': lineItem.price, // Replace with your price ID
    'line_items[0][quantity]': lineItem.quantity,
    mode: 'subscription',
    success_url: redirects.successURL, // Replace with your success URL
    cancel_url: redirects.cancelURL,
    customer_email: email, // Prefill the email address
  });
  const response = await supabaseCreateCheckoutSession(body.toString());
  window.location.href = response.url;
};

export { createCheckoutSession };
