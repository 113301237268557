import {
  USER_MUTATIONS_ENUM,
} from './user.enum';
import { parseFavorites } from './utils/parseFavorites';

export const userMutations = {
  [USER_MUTATIONS_ENUM.SET_USER_DETAILS](state, payload) {
    if (payload) {
      const favorites = payload && payload.favorites ? parseFavorites(payload.favorites) : [];
      state.userDetails = {
        ...payload,
        favorites,
      };
      return;
    }
    state.userDetails = payload;
  },
  [USER_MUTATIONS_ENUM.SET_IS_LOGGED_IN](state, payload) {
    state.isLoggedIn = payload;
  },
  [USER_MUTATIONS_ENUM.SET_USER_ATTRIBUTE](state, payload) {
    state.userDetails[payload.key] = payload.value;
  },
  [USER_MUTATIONS_ENUM.CLEAR_USER_STORE_ON_LOGOUT](state) {
    state.userDetails = null;
    state.isLoggedIn = false;
  },
  [USER_MUTATIONS_ENUM.SET_USER_LOCATION](state, payload) {
    state.location = payload;
  },
};
