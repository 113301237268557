import {
  getKeyValueFromLocalStorage,
} from '../localstorage/getKeyValueFromLocalStorage';
import utilityService from '../utility.service';
import AccountConst from '../../constants/account.constant';
import AppConst from '../../constants/app.constant';

const getFreeTrialExpiresOn = (user) => {
  const ftDuration = AccountConst.ftDu;
  let dateToUseToTestFreeTrial = null;
  const tu = getKeyValueFromLocalStorage(AppConst.localstorageConf.tu);
  let appInstallDateLs = 0;
  if (tu) {
    appInstallDateLs = new Date(tu);
    appInstallDateLs.setHours(0, 0, 0, 0);
  }
  let userAppInstallDate = user.app_install_date;
  if (userAppInstallDate) {
    userAppInstallDate = new Date(userAppInstallDate);
    userAppInstallDate.setHours(0, 0, 0, 0);
    if (userAppInstallDate > appInstallDateLs) {
      dateToUseToTestFreeTrial = appInstallDateLs;
    } else {
      dateToUseToTestFreeTrial = userAppInstallDate;
    }
  }
  if (dateToUseToTestFreeTrial === 0 || !dateToUseToTestFreeTrial) {
    /**
     * Worst case for BNFOR-2120
     * Not able to reproduce it
     * I am considering users free trial is expired
     */
    dateToUseToTestFreeTrial = new Date();
    dateToUseToTestFreeTrial.setMonth(dateToUseToTestFreeTrial.getMonth() - 1);
    dateToUseToTestFreeTrial.setHours(0, 0, 0, 0);
  }
  const freeTrialExpOn = utilityService.addMinutes(dateToUseToTestFreeTrial, ftDuration);
  return freeTrialExpOn;
};

export {
  getFreeTrialExpiresOn,
};
