import { supbaseSignUpNewUser } from '../supabase/supbaseSignUpNewUser';
import AppConst from '../../constants/app.constant';
import { asyncAuthFlow } from './asyncAuthFlow';
import { getTuValue } from '../aws/utils/getTuValue';

const doSupabaseRegister = async ({
  useremail, userpassword, firstname, lastname,
}) => {
  const { error } = await supbaseSignUpNewUser(useremail, userpassword, {
    data: {
      first_name: firstname,
      last_name: lastname,
      app_install_date: getTuValue(),
    },
  });
  if (error) {
    if (error.message === 'User already registered') {
      return {
        message: AppConst.userAlreadyRegistered,
      };
    }
    return {
      message: AppConst.contactSupport,
    };
  }

  await asyncAuthFlow();
};

const authRegister = async ({
  useremail, userpassword, firstname, lastname,
}) => doSupabaseRegister({
  useremail,
  userpassword,
  firstname,
  lastname,
});

export { authRegister };
