import { modalController } from '@ionic/vue';
import Emitter from '../../bus';
import { userAuthSuccess } from './userAuthSuccess';
import { userAuthFailed } from './userAuthFailed';
import { supabaseGetCurrentUserSession } from '../supabase/supabaseGetCurrentUserSession';

const asyncAuthFlow = async (options = {}) => {
  if (localStorage.getItem('supabase_auth')) {
    const currentSession = await supabaseGetCurrentUserSession();
    if (currentSession) {
      if (options?.fromMobileAppMagicLinkLogin) {
        // this is for OTP modal to be dismissed
        modalController.dismiss();
      }
      await userAuthSuccess(options?.fromResumeCheck);

      // emit to hide stripe overlay if exist
      Emitter.emit('hide-stripe-overlay');
    } else {
      await userAuthFailed();
    }
  } else {
    await userAuthFailed();
  }
};

export { asyncAuthFlow };
