<template>
  <div class="logout-button-container">
    <ion-button
      color="light"
      expand="block"
      @click="logOutUser()"
    >
      <ion-icon
        slot="start"
        :icon="logoutIcon"
      /> Logout
    </ion-button>
  </div>
</template>
<script>
import {
  logOut as logoutIcon,
} from 'ionicons/icons';
import {
  IonButton,
  IonIcon,
  alertController,
} from '@ionic/vue';
import AccountConst from '../constants/account.constant';
import { doSignoutTask } from '../services/user/doSignoutTask';

export default {
  components: {
    IonButton,
    IonIcon,
  },
  data() {
    return {
      logoutIcon,
      showingAlert: false,
    };
  },
  methods: {
    logOutUser() {
      const vm = this;
      if (!this.showingAlert) {
        alertController
          .create({
            header: 'Confirm!',
            message: AccountConst.signoutConfirmMessage,
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler() {
                  vm.showingAlert = false;
                },
              },
              {
                text: 'Yes',
                handler() {
                  vm.showingAlert = false;
                  doSignoutTask();
                },
              },
            ],
          })
          .then((alert) => {
            alert.present();
            vm.showingAlert = true;
          });
      }
    },
  },
};
</script>
<style scoped>
.logout-button-container {
  padding: 10px;
}
</style>
