import { supabaseUpdateUser } from '../../../supabase/supabaseUpdateUser';

const setT1T2Cognito = async (t1, t2, iap_tx_details) => supabaseUpdateUser({
  rpc: 'bn_update_iap_details',
  data: {
    iap_product: t1,
    iap_product_expiry: t2,
    iap_tx_details,
    iap_purchase_date: iap_tx_details.purchaseDate,
  },
});
export { setT1T2Cognito };
