<template>
  <ion-list>
    <div v-if="allStripePlans && user.stripe_sub_attrs">
      <div class="custom-divider">
        Membership
      </div>
      <div>
        <AccountActiveMembershipProduct
          v-if="activeStripePlan"
          :product="activeStripePlan"
        />
        <ion-item>
          <ion-label>{{ stripeNextBillingLabel }}</ion-label>
          <ion-label slot="end" class="right-align expiryinfo">
            {{ stripeNextBillingDate }}
          </ion-label>
        </ion-item>
        <ion-item
          v-if="isStripeMembershipActive"
          target="_blank"
          rel="noopener noreferrer"
          :href="stripePortalToUse"
        >
          <ion-label>Manage membership</ion-label>
          <ion-icon
            slot="end"
            :icon="arrowForward"
            mode="ios"
          />
        </ion-item>
      </div>
    </div>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  alertController,
} from '@ionic/vue';
import { arrowForward } from 'ionicons/icons';
import { mapGetters, mapActions } from 'vuex';
import { format } from 'date-fns';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import {
  SUBSCRIPTION_GETTERS_ENUM,
  SUBSCRIPTION_ACTIONS_ENUM,
} from '../store/modules/subscription/subscription.enum';
import { USER_GETTERS_ENUM } from '../store/modules/user/user.enum';
import AppConst from '../constants/app.constant';
import PaypalConst from '../constants/paypal.constant';
import { getIsUserStripeSubscriptionActive } from '../services/user/getIsUserStripeSubscriptionActive';
import AccountActiveMembershipProduct from './AccountActiveMembershipProduct.vue';
import { getStripePortal } from '../services/stripe/getStripePortal';

export default {
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    AccountActiveMembershipProduct,
  },
  data() {
    return {
      arrowForward,
      stripePortalToUse: null,
      showManageMemebership: false,
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.USER, {
      user: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      allStripePlans: SUBSCRIPTION_GETTERS_ENUM.GET_ALL_STRIPE_PLANS,
    }),
    stripeNextBillingDate() {
      const d = new Date(
        Number(`${this.user.stripe_sub_attrs.current_period_end}000`),
      );
      return format(new Date(d), AppConst.buserPreferredDatedFormat);
    },
    stripeNextBillingLabel() {
      return this.isStripeMembershipActive
        ? 'Next billing date'
        : 'Expiration date';
    },
    isStripeMembershipActive() {
      return getIsUserStripeSubscriptionActive(this.user);
    },
    activeStripePlan() {
      const plan = this.allStripePlans.find(
        (item) => item.price_id === this.user.stripe_sub_attrs.plan.id,
      );
      return {
        title: plan.product_name,
        description: '',
        price: `$${plan.unit_amount / 100}`,
      };
    },
  },
  mounted() {
    this.stripePortalToUse = getStripePortal();
  },
  methods: {
    ...mapActions(STORE_NAMESPACE.SUBSCRIPTION, {
      cancelPaypalMembership:
        SUBSCRIPTION_ACTIONS_ENUM.CANCEL_USER_PAYPAL_SUBSCRIPTION,
    }),
    onshowManageMemebership() {
      this.showManageMemebership = !this.showManageMemebership;
    },
    sendSupportEmail(event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      window.open(`mailto:${AppConst.errorReporting.mailto}`, '_self');
    },
    onCancelPaypalMembership() {
      const vm = this;
      alertController
        .create({
          header: 'Feedback',
          message: PaypalConst.alert.cancelPaypalMembership.messageBeforeYouGo,
          buttons: [
            {
              text: 'No',
              handler() {
                vm.confirmCancel();
              },
            },
            {
              text: 'Yes',
              handler() {
                vm.sendSupportEmail();
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    },
    confirmCancel() {
      const vm = this;
      alertController
        .create({
          header: 'Confirm',
          message: PaypalConst.alert.cancelPaypalMembership.message.replace(
            '{EXPIRY_DATE}',
            this.stripeNextBillingDate,
          ),
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Yes',
              handler() {
                vm.cancelPaypalMembership();
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    },
  },
};
</script>
