import { supabase } from '../../supabaseClient';
import reportError from '../error-handler/reportError';

async function supabaseUpdateUser({ rpc, data }) {
  try {
    const result = await supabase.rpc(rpc, { request_payload: data });
    if (result.error) {
      reportError({
        message: result.error.message,
        code: 'RPC failed',
        rpc,
        data,
        result,
      });
      return false;
    }
    return true;
  } catch (error) {
    console.error('Error updating profile data:', rpc, error.message);
    reportError(error);
  }
}

export { supabaseUpdateUser };
