<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div class="ion-page">
    <ion-header>
      <ion-toolbar color="dark">
        <ion-title>WeatherSpork User Agreement</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content id="contents">
      <div v-show="showEulaAgreement">
        <div class="eula">
          <EulaContent />
        </div>
        <div class="eula-buttons-container">
          <ion-button
            color="light"
            expand="block"
            @click="agreedEula"
          >
            I Agree
          </ion-button>
        </div>
      </div>
      <div
        v-show="!showEulaAgreement"
        class="secondary-container"
      >
        <div>
          <ion-spinner name="crescent" />
          <p>Your 14-day trial lasts until {{ expirationDate }}</p>
        </div>
      </div>
    </ion-content>
  </div>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonSpinner,
  modalController,
} from '@ionic/vue';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import { USER_GETTERS_ENUM } from '../store/modules/user/user.enum';
import AppConst from '../constants/app.constant';
import { getFreeTrialExpiresOn } from '../services/user/getFreeTrialExpiresOn';
import EulaContent from './EulaContent.vue';
import { setEulaAgreed } from '../services/aws/user/setAttributes/setEulaAgreed';

export default {
  name: 'EulaComponent',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonSpinner,
    IonButton,
    EulaContent,
  },
  data() {
    return {
      showEulaAgreement: true,
    };
  },
  computed: {
    expirationDate() {
      const d = getFreeTrialExpiresOn(this.userDetails);
      if (d) {
        return format(new Date(d), AppConst.buserPreferredDatedFormat);
      }
      return undefined;
    },
    ...mapGetters(STORE_NAMESPACE.USER, {
      userDetails: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
  },
  mounted() {
    const vm = this;
    if ((vm.userDetails
        && vm.userDetails['custom:trialYN'] === 'Y')
    ) {
      this.showEulaAgreement = false;
    }
    setTimeout(() => {
      vm.showEulaAgreement = true;
    }, AppConst.eulaSecondaryTimeout);
  },
  methods: {
    async agreedEula() {
      const vm = this;
      const { error } = await setEulaAgreed(AppConst.eulaVersion);
      if (!error) {
        vm.closeModal();
      }
    },
    closeModal() {
      modalController.dismiss();
    },
  },
};
</script>
<style scoped>
.eula {
  padding: 10px;
  font-size: 14px;
}
.eula-buttons-container {
  padding: 10px;
}
.secondary-container {
  display: flex;
  height: 100%;
  text-align: center;
  padding: 10px;
}
.secondary-container > div {
  margin: auto;
}
</style>
