import { supabase } from '../../supabaseClient';
import { supabaseGetUserProfile } from './supabaseGetUserProfile';

async function supbaseSignInWithEmail({ username, userpassword }) {
  const { data, error } = await supabase.auth.signInWithPassword({
    email: username,
    password: userpassword,
  });
  if (!error) {
    await supabaseGetUserProfile();
  }
  return { data, error };
}

export { supbaseSignInWithEmail };
