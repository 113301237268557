<template>
  <div class="strip-checkout">
    <Teleport v-if="loading" to="body">
      <div class="big-overlay">
        <div class="stripe-waiting">
          <ion-spinner class="waiting-spinner" name="crescent" color="light" />
          <img src="static/img/credit-cards/stripe.svg" />
          <div>Please wait, your Stripe payment is in progress...</div>
          <ion-button fill="solid" color="dark" @click="loading = false">
            Cancel
          </ion-button>
        </div>
      </div>
    </Teleport>
    <button type="button" @click="submit">
      <img src="static/img/credit-cards/stripe.svg" />
      <ion-spinner v-if="loading" class="stripe-spinner" name="lines" color="dark" />
      <div class="animated animatedFadeInUp fadeInUp credit-cards">
        <img src="static/img/credit-cards/visa.svg" />
        <img src="static/img/credit-cards/mastercard.svg" />
        <img src="static/img/credit-cards/amex.svg" />
        <img src="static/img/credit-cards/discover.svg" />
      </div>
    </button>
  </div>
</template>

<script>
import { IonSpinner, IonButton } from '@ionic/vue';
import { mapGetters } from 'vuex';
import Emitter from '../bus';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import { USER_GETTERS_ENUM } from '../store/modules/user/user.enum';
import { SUBSCRIPTION_GETTERS_ENUM } from '../store/modules/subscription/subscription.enum';
import { getStripePublishKey } from '../services/stripe/getStripePublishKey';
import { createCheckoutSession } from '../services/stripe/createCheckoutSession';
import { getStripeRedirectUrls } from '../services/stripe/getStripeRedirectUrls';
import reportError from '../services/error-handler/reportError';

export default {
  components: {
    IonSpinner,
    IonButton,
  },
  props: {
    productToBuy: {
      type: Object,
      default: null,
    },
  },
  data() {
    this.publishableKey = getStripePublishKey();
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.USER, {
      userDetails: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      stipePlans: SUBSCRIPTION_GETTERS_ENUM.GET_ALL_STRIPE_PLANS,
    }),
    lineItems() {
      const transformedPlans = this.stipePlans
        .filter((item) => item.duration === this.productToBuy.duration)
        .map((plan) => ({
          price: plan.price_id,
          quantity: 1,
        }));

      return transformedPlans;
    },
  },
  mounted() {
    Emitter.on('hide-stripe-overlay', () => {
      this.loading = false;
    });
  },
  methods: {
    async submit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        await createCheckoutSession({
          email: this.userDetails.email,
          lineItem: this.lineItems[0],
          redirects: getStripeRedirectUrls(),
        });
      } catch (ex) {
        this.loading = false;
        reportError(ex);
      }
    },
  },
};
</script>
<style scoped>
.strip-checkout {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  position: relative;
}
.strip-checkout button {
  background: #fff;
  color: #000;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 8px;
  border-radius: 6px;
}
.credit-cards {
  display: flex;
  gap: 4px;
}
.credit-cards img {
  margin-top: 4px;
  width: 32px !important;
}
.strip-checkout img {
  /* width: 100%;
  height: 100%;
  min-width: 200px;
  max-width: 300px;
  background: #fff;
  border-radius: 40px; */
  width: 84px;
}
@keyframes fadeInUp {
  from {
    transform: translate3d(80px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
}
.stripe-spinner {
  position: absolute;
  right: 10px;
  width: 50px;
  height: 50px;
}
.big-overlay {
  background: #000;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999999;
  margin: auto;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
}
.stripe-waiting img {
  width: 200px;
}
.stripe-waiting {
  display: flex;
  gap: 12px;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  text-align: center;
}
.waiting-spinner {
  height: 50px;
  width: 50px;
}
</style>
