import {
  getIsActiveFsUser,
} from './getIsActiveFsUser';
import {
  checkIsFreeTrialExpired,
} from './checkIsFreeTrialExpired';
import {
  checkUserPaypalSubscriptionExpired,
} from './checkUserPaypalSubscriptionExpired';
import {
  checkUserStripeSubscriptionExpired,
} from './checkUserStripeSubscriptionExpired';
import {
  openNeedSubscriptionModalHandle,
} from '../../modal/need-subscription.modal';
// import {
//   openSubscriptionExpireSoonModalHandle,
// } from '../../modal/subscription-expire-soon.modal';
import { getIsActiveApplePayUser } from './getIsActiveApplePayUser';
import { showSubscriptionExpireSoon } from './showSubscriptionExpireSoon';
import reportError from '../error-handler/reportError';
import { getPaypalSubscriptionDetailsFromStore } from '../paypal/getPaypalSubscriptionDetails';

const logNeedSubscription = (
  user,
  isFsExpired,
  isFreeTrialExpired,
  isPaypalMembershipExpired,
  isAppleMembershipExpired,
  userPayPalSubscription,
) => {
  const msg = `NeedSubscription shown to user (${user.email})`;
  reportError({
    message: msg,
    code: msg,
    user,
    isFsExpired,
    isFreeTrialExpired,
    isPaypalMembershipExpired,
    isAppleMembershipExpired,
    userPayPalSubscription,
  });
};

const userNeedSubscription = (user) => {
  /**
   * 1) If Free trial is expired - DONE
   * 2) If FS has expired - DONE
   * 3) If Paypal is expired if status is not ACTIVE or (t3) - DONE
   * 4) If IAP is expired (t2) - DONE
   * 5) If no subscription at all - If all of above true
   */
  // return false;//temp
  if (user && user.email) {
    const userPayPalSubscription = getPaypalSubscriptionDetailsFromStore();
    const isFsExpired = !getIsActiveFsUser(user);
    const isFreeTrialExpired = checkIsFreeTrialExpired(user);
    const isStripeSubscriptionExpired = checkUserStripeSubscriptionExpired(user);
    const isPaypalMembershipExpired = checkUserPaypalSubscriptionExpired(
      user,
      userPayPalSubscription,
    );
    const isAppleMembershipExpired = !getIsActiveApplePayUser(user);
    if (
      isFsExpired === true
      && isStripeSubscriptionExpired === true
    && isPaypalMembershipExpired === true
    && isFreeTrialExpired === true
    && isAppleMembershipExpired === true) {
      openNeedSubscriptionModalHandle();
      logNeedSubscription(
        user,
        isFsExpired,
        isFreeTrialExpired,
        isPaypalMembershipExpired,
        isAppleMembershipExpired,
        userPayPalSubscription,
      );
    } else if (showSubscriptionExpireSoon(user)) {
      // openSubscriptionExpireSoonModalHandle();
    }
  } else {
    reportError({
      message: 'Reached to userNeedSubscription',
      code: 'Attempted needSubscription check without user',
      data: user,
      name: 'Attempted needSubscription check without user',
    });
  }
};
export {
  userNeedSubscription,
};
